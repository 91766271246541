import * as React from "react"
import Wrapper from "./Wrapper"

const Tags = props => {
    const { title, tags } = props
    const tagsArray = tags.split(" ")
    return (
        <Wrapper className="mb-8">
            {title && (
                <h2 className="text-center font-extrabold mb-6">{title}</h2>
            )}
            <div className="max-w-4xl mx-auto mt-2 mb-8 flex flex-wrap items-center text-center justify-center">
                {tagsArray?.map((tag, idx) => {
                    return (
                        <p
                            key={idx}
                            className="bg-hybl-100 px-2 mx-1 mb-3 rounded-full font-semibold"
                        >
                            {tag}
                        </p>
                    )
                })}
            </div>
        </Wrapper>
    )
}

export default Tags
