import * as React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"
import Sections from "../components/Sections"
import Wrapper from "../components/Wrapper"
import Icon from "../components/Icon"
import Breadcrumbs from "../components/Breadcrumbs"

const Service = ({ data }) => (
    <Layout>
        <HelmetDatoCms>
            <title>
                {data.datoCmsService.siteseo &&
                    data.datoCmsService.siteseo.title}
            </title>
            <meta
                name="description"
                content={
                    data.datoCmsService.siteseo &&
                    data.datoCmsService.siteseo.description
                }
            />
        </HelmetDatoCms>
        <div className="mt-24 md:mt-36">
            <Wrapper className="mb-2 md:mb-16">
                <h1 className="font-extrabold text-4xl mb-4 flex gap-x-3 items-center">
                    <span className="text-hybl-300">
                        <Icon name={data.datoCmsService.icon} size="2rem" />
                    </span>
                    {data.datoCmsService.title}
                </h1>
                <div
                    className="max-w-2xl text-lg"
                    dangerouslySetInnerHTML={{
                        __html: data.datoCmsService.description,
                    }}
                />
            </Wrapper>
            <Sections sections={data.datoCmsService.blocks} />
        </div>
    </Layout>
)

export default Service

export const query = graphql`
    query ServiceQuery($slug: String!) {
        datoCmsService(slug: { eq: $slug }) {
            title
            slug
            description
            icon
            siteseo {
                title
                description
            }
            blocks {
                ... on DatoCmsText {
                    ...textFields
                }
                ... on DatoCmsImage {
                    ...imageFields
                }
                ... on DatoCmsContact {
                    ...contactFields
                }
            }
        }
    }
`
