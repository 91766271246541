import * as React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const CaseWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    @media (min-width: ${props => props.theme.size.medium}) {
        width: 50%;
        padding: 10px;
    }
`
const CaseImage = styled(GatsbyImage)`
    transition: 0.4s ease-out;
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;
`

const CaseImageLink = styled(Link)`
    display: block;
    text-decoration: none;
    color: unset;
    position: relative;
    padding-bottom: 110%;
    transition: 0.2s ease-out;
    overflow: hidden;
    & > div:last-child {
        transition: 0.2s ease-out;
    }
    &:hover {
        transform: scale(0.95);
        & > div:last-child {
            transform: scale(1.1);
        }
    }
`

const CaseTitle = styled.div`
    position: absolute;
    z-index: 1;
    color: white;
    padding: 25px;
    text-transform: uppercase;
    max-width: 100%;
    overflow-wrap: break-word;
    p {
        font-weight: bold;
        letter-spacing: 1.5px;
        margin: 0;
        color: white;
    }
    h2 {
        font-weight: 800;
        margin: 0;
    }
`

const CasePreview = props => (
    <CaseWrapper>
        <CaseImageLink to={`/cases/${props.slug}`}>
            <CaseTitle>
                <p>{props.tag}</p>
                <h2>{props.title}</h2>
            </CaseTitle>
            <CaseImage alt="" image={props.image} />
        </CaseImageLink>
    </CaseWrapper>
)

export default CasePreview
