import * as React from "react"
import SectionWrapper from "./SectionWrapper"
import Background from "./Background"
import { GatsbyImage } from "gatsby-plugin-image"

const ImageWithText = props => (
    <Background id={props.id} color={props.color}>
        <SectionWrapper flip={props.flip} className="imageSection">
            <div>
                <h2 className="text-2xl md:text-3xl font-extrabold">
                    {props.title}
                </h2>
                <div
                    className="mt-3"
                    dangerouslySetInnerHTML={{ __html: props.text }}
                />
            </div>
            <GatsbyImage alt="" image={props.image} />
        </SectionWrapper>
    </Background>
)

export default ImageWithText
