import styled from "styled-components"
import Wrapper from "./Wrapper"

const SectionWrapper = styled(Wrapper)`
    padding-bottom: ${props => (props.padY ? props.padY : "50px")};
    padding-top: ${props => (props.padY ? props.padY : "50px")};
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    &.imageSection {
        flex-direction: column-reverse;
        & > div {
            width: 100%;
        }
        @media (min-width: ${props => props.theme.size.medium}) {
            & > div:first-child {
                width: 50%;
            }
            & > div:last-child {
                width: 50%;
            }
        }
        @media (min-width: ${props => props.theme.size.medium}) {
            flex-direction: ${props => (props.flip ? "row-reverse" : "row")};
        }
    }

    .gatsby-image-wrapper {
        * {
            object-fit: contain !important;
        }
    }
`

export default SectionWrapper
