import * as React from "react"
import Wrapper from "../components/Wrapper"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const LogoBanner = props => {
    const { logos, title } = props
    return (
        <Wrapper>
            <div className="text-center my-16">
                <h2 className=" text-black font-extrabold">{title}</h2>
                <div className="flex flex-wrap justify-center lg:gap-x-8 gap-x-4">
                    {logos.map((logo, idx) => (
                        <GatsbyImage
                            key={idx}
                            className="basis-1/5 lg:basis-1/12 aspect-square saturate-0 opacity-90 hover:opacity-100 hover:saturate-100 transition-all"
                            image={getImage(logo)}
                            alt=""
                        />
                    ))}
                </div>
            </div>
        </Wrapper>
    )
}

export default LogoBanner
