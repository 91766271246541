import classNames from "classnames"
import React from "react"
import Icon from "./Icon"
import Wrapper from "./Wrapper"

const Text = ({ data }) => {
    console.log("d", data)
    return (
        <Wrapper
            className={classNames(
                "my-12 md:my-24 rte",
                { "!max-w-5xl": data.tightWrapper },
                { "text-center": data.centerText }
            )}
        >
            <div
                className={classNames(
                    "flex items-center gap-x-4 mb-5 max-w-full w-full",
                    { "justify-center": data.centerText }
                )}
            >
                {data.icon && (
                    <span className="text-hybl-300 hidden md:inline-block">
                        <Icon name={data.icon} size={"2rem"} />
                    </span>
                )}
                <h1
                    className="shrink font-extrabold text-2xl md:text-4xl break-words max-w-full"
                    dangerouslySetInnerHTML={{ __html: data.heading }}
                />
            </div>
            <div
                className={classNames({ "text-lg": data.enlargeText })}
                dangerouslySetInnerHTML={{ __html: data.content }}
            />
        </Wrapper>
    )
}

export default Text
