import * as React from "react"
import Wrapper from "./Wrapper"
import { Link } from "gatsby"
import Icon from "./Icon"

const ServiceRow = ({ data }) => {
    return (
        <Wrapper id={data.anchorId} className="mb-16 md:mb-32 mt-12">
            <h2 className="text-center font-extrabold mb-6">{data.title}</h2>
            <div className="max-w-4xl mx-auto mt-2 mb-8 flex gap-6 flex-wrap items-center text-center justify-center">
                {data.services.map((service, idx) => {
                    return (
                        <Link
                            key={idx}
                            className="flex-1 group"
                            to={`/services/${service.slug}`}
                        >
                            <div className="group-hover:scale-95 transition-all duration-300 flex-1 text-left p-4 md:p-6 md:px-7 bg-gray-100">
                                <h4 className="text-black text-xl font-bold mb-2 flex gap-x-2 items-center">
                                    <span className="text-hybl-300">
                                        <Icon
                                            name={service.icon}
                                            size={"1.25rem"}
                                        />
                                    </span>
                                    {service.title}
                                </h4>
                                <div
                                    className=""
                                    dangerouslySetInnerHTML={{
                                        __html: service.excerpt,
                                    }}
                                />
                            </div>
                        </Link>
                    )
                })}
            </div>
        </Wrapper>
    )
}
export default ServiceRow
