import { getImage } from "gatsby-plugin-image"
import React from "react"
import CasePreview from "./CasePreview"
import PrimaryButton from "./PrimaryButton"
import Wrapper from "./Wrapper"

const Cases = ({ data }) => {
    return (
        <Wrapper id={data.anchorId} className="flex flex-wrap justify-center">
            {data.cases.map((work, idx) => (
                <CasePreview
                    key={idx}
                    title={work.title}
                    tag={work.tag}
                    slug={work.slug}
                    excerpt={work.excerpt}
                    image={getImage(work.featuredImage[0])}
                />
            ))}
            {data.linkToCases && (
                <div className="flex justify-center my-4 mt-12">
                    <PrimaryButton
                        to="/cases"
                        primaryButtonText="SE ALLE CASES"
                    />
                </div>
            )}
        </Wrapper>
    )
}

export default Cases
