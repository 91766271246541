import styled from "styled-components"

const Background = styled.div`
    background: ${props =>
        props.gradient
            ? "linear-gradient(185deg, " +
              props.colorTop +
              " 50%," +
              props.colorBottom +
              " 50%)"
            : props.color};
`

export default Background