import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useEffect, useState } from "react"

const PersonCard = ({ data }) => {
    const [hovering, setHovering] = useState(false)
    const [rotation, setRotation] = useState(0)

    useEffect(() => {
        const interval = setInterval(() => {
            if (hovering) {
                setRotation(rotation + 0.1)
            }
        }, 1)
        return () => clearInterval(interval)
    }, [hovering, rotation])

    return (
        <div className="max-w-xs text-center font-medium text-md">
            <div
                onMouseEnter={() => setHovering(true)}
                onMouseLeave={() => {
                    setHovering(false)
                    setRotation(0)
                }}
            >
                <GatsbyImage
                    className="rounded-full rounded-image mb-4 "
                    alt=""
                    style={{
                        rotate: `${rotation}deg`,
                    }}
                    image={getImage(data.image)}
                />
            </div>

            <h3
                className="font-extrabold text-lg"
                dangerouslySetInnerHTML={{ __html: data.name }}
            />
            <p
                className="text-sm mb-3"
                dangerouslySetInnerHTML={{ __html: data.title }}
            />
            {data.phone && (
                <a
                    className="block inline-link"
                    href={`tel:${data.phone}`}
                    dangerouslySetInnerHTML={{ __html: data.phone }}
                />
            )}
            {data.eMail && (
                <a
                    className="block inline-link"
                    href={`mailto:${data.eMail}`}
                    dangerouslySetInnerHTML={{ __html: data.eMail }}
                />
            )}
        </div>
    )
}

export default PersonCard
