import React from "react"
import PersonCard from "./PersonCard"
import Wrapper from "./Wrapper"

const PeopleList = ({ data }) => {
    return (
        <Wrapper className="text-center">
            {data.title && (
                <h2
                    className="font-extrabold text-4xl mb-12"
                    dangerouslySetInnerHTML={{ __html: data.title }}
                />
            )}
            <div className="flex flex-wrap justify-center gap-8 md:gap-16">
                {data.people.map((person, idx) => (
                    <PersonCard key={idx} data={person} />
                ))}
            </div>
        </Wrapper>
    )
}

export default PeopleList
