import * as React from "react"
import { Link } from "gatsby";

const PrimaryButton = (props) => (
    <Link className={`${props.className} group`} to={props.to}> 
        <div className="group-hover:scale-95 group-hover:brightness-110 transition-all duration-300 bg-hybl text-white flex items-center justify-center text-center py-4 px-5 font-semibold tracking-wider text-xs">{props.primaryButtonText}</div>
    </Link>
  )
   
  export default PrimaryButton;
 