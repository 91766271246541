import { getImage } from "gatsby-plugin-image"
import React from "react"
import Cases from "./Cases"
import ContactPrompt from "./ContactPrompt"
import ImageWithText from "./ImageWithText"
import LogoBanner from "./LogoBanner"
import PeopleList from "./PeopleList"
import ServiceRow from "./ServiceRow"
import Tags from "./Tags"
import Text from "./Text"

const Sections = ({ sections, className }) => {
    return (
        <div className={className}>
            {sections.map((section, idx) => {
                switch (section.model.apiKey) {
                    case "image":
                        return (
                            <section key={idx}>
                                <ImageWithText
                                    title={section.title}
                                    image={getImage(section.image)}
                                    text={section.description}
                                    flip={section.flip}
                                    color={
                                        section.color.rgb ||
                                        "rgb(255, 255, 255)"
                                    }
                                />
                            </section>
                        )
                    case "contact":
                        return (
                            <section key={idx}>
                                <ContactPrompt
                                    title={section.title}
                                    description={section.description}
                                    number={section.phoneNumber}
                                    mail={section.eMail}
                                />
                            </section>
                        )
                    case "people_list":
                        return (
                            <section key={idx}>
                                <PeopleList data={section} />
                            </section>
                        )
                    case "text":
                        return (
                            <section key={idx}>
                                <Text data={section} />
                            </section>
                        )
                    case "logo_banner":
                        return (
                            <section key={idx}>
                                <LogoBanner
                                    logos={section.logos}
                                    title={section.title}
                                />
                            </section>
                        )
                    case "tag_row":
                        return (
                            <section key={idx}>
                                <Tags
                                    title={section.title}
                                    tags={section.tags}
                                />
                            </section>
                        )
                    case "service_row":
                        return (
                            <section key={idx}>
                                <ServiceRow data={section} />
                            </section>
                        )
                    case "case_list":
                        return (
                            <section key={idx}>
                                <Cases data={section} />
                            </section>
                        )
                    default:
                        return <></>
                }
            })}
        </div>
    )
}

export default Sections
